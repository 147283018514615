import { useContext, useState } from "react";
import { AppContext } from "../App";
import FormGroup from "./form/FormGroup";
import api from "./api/api";

function Settings() {
	const {
		apiSettings,
		setSettingsVisible,
		config,
		setConfig,
		fiscalPrinters,
		thermalPrinters,
		virtualPrinters,
		filteredCategories,
		shop,
		setUser,
	} = useContext(AppContext);
	const [page, setPage] = useState("main");

	const saveColor = async (request) => {
		await api.postProtected("/pos2/save_color/", request);
	};

	return (
		<div className="settings">
			<div className="settings-container">
				<div className="settings-header">
					<h2>Impostazioni</h2>
					<button onClick={() => setSettingsVisible(false)}>&times;</button>
				</div>
				<div className="settings-columns">
					<div className="settings-menu">
						<button
							className={page == "main" ? "selected" : ""}
							onClick={() => setPage("main")}
						>
							Generali
						</button>
						<button
							className={page == "visual" ? "selected" : ""}
							onClick={() => setPage("visual")}
						>
							Visualizzazione
						</button>
						<button
							className={page == "flows" ? "selected" : ""}
							onClick={() => setPage("flows")}
						>
							Portate
						</button>
						<button
							className={page == "products" ? "selected" : ""}
							onClick={() => setPage("products")}
						>
							Prodotti
						</button>
						<button
							className={page == "local_server" ? "selected" : ""}
							onClick={() => setPage("local_server")}
						>
							Server Locale
						</button>
						<button
							className={page == "thermal_printer" ? "selected" : ""}
							onClick={() => setPage("thermal_printer")}
						>
							Stampante Comande
						</button>
						{apiSettings?.mandant?.fiscal_printers == 1 && (
							<button
								className={page == "fiscal_printer" ? "selected" : ""}
								onClick={() => setPage("fiscal_printer")}
							>
								Stampante Fiscale
							</button>
						)}
						{apiSettings?.mandant?.fiskaly == 1 && (
							<button
								className={page == "virtual_printer" ? "selected" : ""}
								onClick={() => setPage("virtual_printer")}
							>
								Stampante Virtuale
							</button>
						)}
					</div>
					{page == "main" && (
						<div className="settings-content">
							<h2>Generali</h2>
							<FormGroup
								label="Conferma ordine al salvataggio"
								name="confirm_order"
								type="select"
								value={config.confirm_order}
								onChange={(e) =>
									setConfig({ ...config, confirm_order: e.target.value })
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
							<FormGroup
								label="Mantieni l'ordine aperto dopo l'invio"
								name="keep_open_after_checkout"
								type="select"
								value={config.keep_open_after_checkout}
								onChange={(e) =>
									setConfig({
										...config,
										keep_open_after_checkout: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
							<FormGroup
								label="Non richidere il metodo di pagamento per gli ordini già pagati"
								name="skip_payment_request_for_payed"
								type="select"
								value={config.skip_payment_request_for_payed}
								onChange={(e) =>
									setConfig({
										...config,
										skip_payment_request_for_payed: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
						</div>
					)}
					{page == "visual" && (
						<div className="settings-content">
							<h2>Visualizzazione</h2>
							<FormGroup
								label="Altezza pulsante prodotto"
								name="product_button_height"
								type="select"
								value={config.product_button_height}
								onChange={(e) =>
									setConfig({ ...config, product_button_height: e.target.value })
								}
								options={[
									{ name: "Fissa", id: "fixed" },
									{ name: "Auto", id: "auto" },
								]}
							/>
							<FormGroup
								label="Posizione pulsanti Ordini e Tavoli"
								name="buttons_position"
								type="select"
								value={config.buttons_position}
								onChange={(e) =>
									setConfig({ ...config, buttons_position: e.target.value })
								}
								options={[
									{ name: "In alto", id: "top" },
									{ name: "In basso", id: "bottom" },
								]}
							/>
						</div>
					)}
					{page == "flows" && (
						<div className="settings-content">
							<h2>Portate</h2>
							<FormGroup
								label="Abilita portate"
								name="enable_flows"
								type="select"
								value={config.enable_flows}
								onChange={(e) =>
									setConfig({ ...config, enable_flows: e.target.value })
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
							<FormGroup
								label="Numero di portate"
								name="flows_number"
								type="select"
								value={config.flows_number}
								onChange={(e) =>
									setConfig({ ...config, flows_number: e.target.value })
								}
								options={[
									{ name: "1", id: 1 },
									{ name: "2", id: 2 },
									{ name: "3", id: 3 },
									{ name: "4", id: 4 },
									{ name: "5", id: 5 },
								]}
							/>
						</div>
					)}
					{page == "products" && (
						<div className="settings-content">
							<h2>Prodotti</h2>
							<div className="products-color">
								{filteredCategories.map((course, i) => (
									<div key={i}>
										<h3>{course.name || "Altro"}</h3>
										{course.categories.map((category, n) => (
											<div key={n}>
												<h4>{category.name}</h4>
												{category.products.map((product, y) => (
													<div key={y}>
														<div className="product">
															{product.name}
															<div className="buttons">
																{[
																	"#f94144",
																	"#d264b6",
																	"#f8961e",
																	"#f4acb7",
																	"#f9c74f",
																	"#90be6d",
																	"#43aa8b",
																	"#4d908e",
																	"#577590",
																	"#277da1",
																].map((color, i) => (
																	<button
																		key={i}
																		style={{
																			backgroundColor: color,
																		}}
																		onClick={() =>
																			saveColor({
																				shop_id: shop.id,
																				product_id:
																					product.id,
																				color: color,
																			})
																		}
																	>
																		{product.color == color && (
																			<>&#x2714;</>
																		)}
																	</button>
																))}
															</div>
														</div>
													</div>
												))}
											</div>
										))}
									</div>
								))}
							</div>
						</div>
					)}
					{page == "local_server" && (
						<div className="settings-content">
							<h2>Server Locale</h2>
							<FormGroup
								label="Modalità"
								name="mode"
								type="select"
								value={config.mode}
								onChange={(e) => {
									setConfig({ ...config, mode: e.target.value });
									setUser(false);
									localStorage.removeItem("pos-user");
									setTimeout(() => {
										window.location.reload();
									}, 500);
								}}
								options={[
									{ name: "Cloud", id: "remote" },
									{ name: "Server locale", id: "local" },
								]}
							/>
						</div>
					)}
					{page == "fiscal_printer" && (
						<div className="settings-content">
							<h2>Stampante Fiscale</h2>
							<FormGroup
								label="Stampante"
								name="fiscal_printer_id"
								type="select"
								value={config.fiscal_printer_id}
								onChange={(e) =>
									setConfig({ ...config, fiscal_printer_id: e.target.value })
								}
								options={[{ id: null, name: "" }, ...fiscalPrinters]}
							/>
							<FormGroup
								label="Attiva controllo chiusura giornaliera"
								name="check_fiscal_closure"
								type="select"
								value={config.check_fiscal_closure}
								onChange={(e) =>
									setConfig({
										...config,
										check_fiscal_closure: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
						</div>
					)}
					{page == "thermal_printer" && (
						<div className="settings-content">
							<h2>Stampante Comande</h2>
							<FormGroup
								label="Stampante Primaria"
								name="thermal_printer_id"
								type="select"
								value={config.thermal_printer_id}
								onChange={(e) =>
									setConfig({ ...config, thermal_printer_id: e.target.value })
								}
								options={[{ id: null, name: "" }, ...thermalPrinters]}
							/>
							<FormGroup
								label="Stampa preconto sulla stampante primaria alla chiusura"
								name="prebill_on_checkout"
								type="select"
								value={config.prebill_on_checkout}
								onChange={(e) =>
									setConfig({
										...config,
										prebill_on_checkout: e.target.value,
									})
								}
								options={[
									{ name: "No", id: 0 },
									{ name: "Si", id: 1 },
								]}
							/>
						</div>
					)}
					{page == "virtual_printer" && (
						<div className="settings-content">
							<h2>Stampante Virtuale</h2>
							<FormGroup
								label="Stampante"
								name="virtual_printer_id"
								type="select"
								value={config.virtual_printer_id}
								onChange={(e) =>
									setConfig({ ...config, virtual_printer_id: e.target.value })
								}
								options={[{ id: null, name: "" }, ...virtualPrinters]}
							/>
						</div>
					)}
				</div>
				<div className="settings-footer">
					<button onClick={() => setSettingsVisible(false)}>SALVA</button>
				</div>
			</div>
		</div>
	);
}

export default Settings;
