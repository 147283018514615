import React, { useContext } from "react";
import { AppContext } from "../App";

export default function QrPopup() {
	const { setQrPopupVisible, table } = useContext(AppContext);

	return (
		<div className="qr-popup">
			<div className="qr-popup-container">
				<header>
					<div className="buttons-group">
						<button className="qr-popup-close" onClick={() => setQrPopupVisible(false)}>
							&times;
						</button>
						<div>
							<div>{table.name}</div>
							<div className="extrasmall">{table.room.name}</div>
						</div>
					</div>
				</header>
				<div className="content qr-code-container">
					<div className="pin">
						<div>PIN</div>
						<div>{table.pin}</div>
					</div>
					<img
						src={
							"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&w=500&h=500&d=" +
							encodeURIComponent(table.url)
						}
					/>
					<div className="extrasmall">
						<a href={table.url} target="_blank">
							{table.url}
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
