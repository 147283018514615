import { useContext, useState } from "react";
import { AppContext } from "../App";
import PopUp from "./PopUp";
import PlusMinusLarge from "./ui/PlusMinusLarge";
import functions from "../functions/functions";

export default function OpenTablePopup() {
	const {
		apiSettings,
		setOpenTablePopupVisible,
		table,
		user,
		lists,
		setTablesListVisible,
		init_cart,
		setTablePopup,
		menu,
		editTable,
		shop,
		setConfirm,
	} = useContext(AppContext);
	const [covers, setCovers] = useState(0);
	const [list, setList] = useState(table.list_id);
	const [splittedCovers, setSplittedCovers] = useState([]);
	const [singleOrderMode, setSingleOrderMode] = useState(table.single_order_mode);

	const openTable = async () => {
		if (list && list.allyoucaneat == 1 && splittedCovers.length == 0) {
			setConfirm({
				title: "Errore",
				message: "Nessun menù selezionato",
			});
			return;
		}

		const openTablerequest = {
			status: 1,
			user_id: user.id,
			covers: covers,
			list_id: list ? list.id : null,
			single_order_mode: singleOrderMode,
		};
		if (list && list.allyoucaneat == 1) openTablerequest.splitted_covers = splittedCovers;
		const res = await editTable(table.id, openTablerequest);
		const cart_id = res.cart_id;

		const products = [];
		if (singleOrderMode == 1 || apiSettings.orders.generate_covers_order == 0) {
			if (!list || lists.allyoucaneat != 1) {
				if (covers > 0) {
					const coverProduct = findProduct("is_cover", 1);
					console.log(coverProduct);
					if (coverProduct) products.push({ ...coverProduct, qty: covers, flow: 1 });
				}
				const serviceProduct = findProduct("is_service", 1);
				console.log(serviceProduct);
				if (serviceProduct) products.push({ ...serviceProduct, qty: 1, flow: 1 });
			}
		}

		init_cart({
			covers: covers,
			list_id: list ? list.id : null,
			table: { ...table, cart_id: cart_id },
			room_id: table.room_id,
			delivery_type: {
				id: "shop",
				name: "Sala",
			},
			delivery_date: null,
			address: false,
			delivery_time_range: null,
			self_cart_id: cart_id,
			products: products,
		});
		setTablesListVisible(false);
		setOpenTablePopupVisible(false);
		setTablePopup(false);
	};

	const incrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			const newData = [...splittedCovers];
			newData[index].qty++;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const decrementSplittedCovers = (id) => {
		const index = splittedCovers.findIndex((el) => el.id == id);
		if (index > -1) {
			if (splittedCovers[index].qty == 0) return;
			const newData = [...splittedCovers];
			newData[index].qty--;
			setSplittedCovers(newData);
		} else {
			setSplittedCovers([...splittedCovers, { id: id, qty: 1 }]);
		}
	};

	const getSplittedCovers = (id) => {
		const el = splittedCovers.find((el) => el.id == id);
		if (el) return el.qty;
		else return 0;
	};

	const getFilteredProducts = (filter) => {
		const res = [];
		for (const course of menu) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (product[filter] == 1) {
						if (list) {
							const priceList = product.prices.find(
								(p) => p.list_id == list.id && p.price
							);
							const assortment = product.assortment.find(
								(el) =>
									el.shop_id == shop.id && el.list_id == list.id && el.active == 1
							);
							console.log("priceList", priceList);
							if (priceList && assortment)
								res.push({ ...product, price: priceList.price });
						} else res.push(product);
					}
				}
			}
		}
		console.log("getFilteredProducts", filter, res);
		return res;
	};

	const findProduct = (field, value) => {
		for (const course of menu) {
			for (const category of course.categories) {
				for (const product of category.products) {
					//console.log("product", product.name, product[field]);
					if (product[field] == value) {
						if (list) {
							const priceList = product.prices.find(
								(p) => p.list_id == list.id && p.price
							);
							if (priceList) return { ...product, price: priceList.price };
						} else return product;
					}
				}
			}
		}
	};

	return (
		<PopUp
			size="sm"
			options={{
				onClose: setOpenTablePopupVisible,
				title: table.name,
				content: (
					<div>
						<h3 className="text-center">Listino</h3>
						<div className="lists-selector">
							<button
								className={"button" + (!list ? " button-1" : " button-3")}
								onClick={() => setList(false)}
							>
								Base
							</button>
							{lists.map((l, i) => (
								<button
									key={i}
									className={
										"button" +
										(list && l.id == list.id ? " button-1" : " button-3")
									}
									onClick={() => setList(l)}
								>
									{l.name}
								</button>
							))}
						</div>
						{(!list || list.allyoucaneat != 1) && (
							<>
								<h3 className="text-center">Ordine singolo</h3>
								<div className="lists-selector">
									<button
										className={
											"button" +
											(singleOrderMode == 0 ? " button-1" : " button-3")
										}
										onClick={() => setSingleOrderMode(0)}
									>
										No
									</button>
									<button
										className={
											"button" +
											(singleOrderMode == 1 ? " button-1" : " button-3")
										}
										onClick={() => setSingleOrderMode(1)}
									>
										Si
									</button>
								</div>
								<h3 className="text-center">Coperti</h3>
								<PlusMinusLarge value={covers} onChange={setCovers} />
							</>
						)}
						{list && list.allyoucaneat == 1 && (
							<>
								<h3 className="text-center">Scegli i menu</h3>
								<div className="menu-selector">
									{getFilteredProducts("is_selfordering_menu").map(
										(product, i) => (
											<div key={i}>
												<div>{product.name}</div>
												<div>
													{functions.formatter.format(product.price)}
													<button
														className="button button-1 square"
														onClick={() =>
															decrementSplittedCovers(product.id)
														}
													>
														-
													</button>
													<div>{getSplittedCovers(product.id)}</div>
													<button
														className="button button-1 square"
														onClick={() =>
															incrementSplittedCovers(product.id)
														}
													>
														+
													</button>
												</div>
											</div>
										)
									)}
								</div>
							</>
						)}
						<button className="button button-2" onClick={openTable}>
							Apri tavolo
						</button>
					</div>
				),
			}}
		/>
	);
}
